import { getVolToken } from '../../api/dApi';
export default {
  name: "DataVol",
  data: function data() {
    return {
      tokenVol: ''
    };
  },
  mounted: function mounted() {
    this.getVolToken();
  },
  methods: {
    getVolToken: function getVolToken() {
      var _this = this;
      var that = this;
      this.service.axiosUtils({
        requestName: "getVolToken",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.tokenVol = res.data.token;
            if (that.tokenVol) {
              window.embedSDK.render({
                // 页面的容器元素，例如
                targetNode: document.getElementById("vol-container"),
                // DataFinder 应用的地址，火山引擎版本的地址如下：
                host: "https://console.volcengine.com/",
                // DataFinder 应用的 AppID
                appId: 20000869,
                // 页面 ID，dashboard 表示看板
                page: "dashboard",
                // 当嵌入页面为看板时，需要指定看板 ID
                dashboardId: "7226660362026746368",
                // 在第三步中获取到的 Token
                dorToken: that.tokenVol // 形式如 "eyJ0eXAiOiJKV1QiLCxxxxiJIUzI1NiJ9.xxxxxxx"
              });
            }
            // this.init()
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    init: function init() {
      var that = this;
      console.log(that.tokenVol);
      window.embedSDK.render({
        // 页面的容器元素，例如
        targetNode: document.getElementById("vol-container"),
        // DataFinder 应用的地址，火山引擎版本的地址如下：
        host: "https://console.volcengine.com/",
        // DataFinder 应用的 AppID
        appId: 20000869,
        // 页面 ID，dashboard 表示看板
        page: "dashboard",
        // 当嵌入页面为看板时，需要指定看板 ID
        dashboardId: "7226660362026746368",
        // 在第三步中获取到的 Token
        dorToken: that.tokenVol // 形式如 "eyJ0eXAiOiJKV1QiLCxxxxiJIUzI1NiJ9.xxxxxxx"
      });
    }
  }
};